<template>
  <!-- 科普管理 -->
  <div class="communal" ref="communal">
    <!-- 顶部固定 -->
    <div class="top-fixed" ref="topFixed">
      <div class="container fixed-style">
        <el-row>
          <el-col :span="5" class="text-left">
            <el-button type="success" style="margin-left: 0;" @click="addEdit('')">新增科普</el-button>
          </el-col>
          <el-col :span="16">
            <el-input placeholder="请输入关键字查询" v-model="content"></el-input>
            <el-button type="success" plain @click="serach">搜索</el-button>
          </el-col>
          <!-- <el-col :span="6">
            <el-button type="success" plain @click="goMz()">门诊</el-button>
          </el-col> -->
        </el-row>
      </div>
      <div class="interval"></div>

    </div>
    <!-- 内容 -->
    <div class="content">
      <div class="container" :style="{height:contentHeight+'px'}">
        <template>
          <el-table :data="list" style="width: 100%" :height="contentHeight" :empty-text="tableTip">
            <el-table-column prop="title" label="科普标题" min-width="200" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="addtime" label="发布时间">
            </el-table-column>
            <el-table-column label="操作" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" @click.stop="addEdit(scope.row.id)">编辑</el-button>
                <el-button type="text" @click.stop="operation(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
    </div>
    <!-- 分页 100-->
    <div class="paging" ref="paging">
      <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage" :page-sizes="[10, 50, 100, 2000]" :page-size="psize"
        layout="total, sizes, prev, pager, next, jumper" :total="totals"></el-pagination>
    </div>
    <operationBtn v-if="showHide" :issue="issue" :hintTxt="hintTxt" :showHide="showHide" :operationId="operationId" :operationPsotUrl="operationPsotUrl" :operationPsotName="operationPsotName"
      @childByOperationBtn="childByOperationBtn"></operationBtn>
  </div>
</template>
<script>
import operationBtn from "../../components/operationBtn";
import { ArticleList } from "../../api/api"; //列表
import { DelArticle } from "../../api/api"; //删除
export default {
  components: {
    operationBtn,
  },
  data() {
    return {
      topHeight: "", //顶部搜索的高度
      mainHeight: "", //获取当前容器的高度
      pagingHeight: "", //获取分页的高度
      contentHeight: 0, //内容的高度
      totals: 0,
      pages: "1",
      psize: 10,
      currentPage: 1, //默认显示页
      content: "", //搜索框内容
      tableTip: "系统正在检索中…",
      list: [], //数据
      // 操作性按钮弹框值   start
      issue: "", //问题   上面的文字
      hintTxt: "", //提示文本   下方的文字
      showHide: false, //弹框的隐藏显示
      operationId: "", //操作id
      operationPsotUrl: "", //操作接口名字
      operationPsotName: "", //请求参数名字
      // 操作性按钮弹框值   end
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    var that = this;
    that.common.Count.pageResize(that);
    window.onresize = function temp() {
      that.common.Count.pageResize(that);
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.pages = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.psize = val;
      this.getList();
    },
    serach() {
      //搜索
      this.getList("搜索");
    },
    getList(operationName) {
      //账号列表   operationName判断是列表还是点击了搜索
      var that = this;
      var db = {
        pages: this.pages,
        pnums: this.psize,
        name: this.content, //搜索内容
      };
      this.$axios.post(ArticleList, db).then(
        (res) => {
          if (res.data.code == 1) {
            if (res.data.data != undefined && res.data.data.length > 0) {
              this.list = res.data.data;
              this.totals = res.data.count;
            } else {
              this.list = [];
              this.totals = res.data.count;
              if (operationName == undefined) {
                this.tableTip = "暂无科普";
              } else {
                this.tableTip = "暂无搜索结果";
              }
            }
          } else {
            this.list = [];
            this.tableTip = "暂无科普";
          }
        },
        (err) => {
          this.list = [];
          this.tableTip = "暂无科普";
        }
      );
    },
    addEdit(id) {
      //新增修改
      this.$router.push({
        name: "contentManageAddEditNews",
        query: { id: id },
      });
    },
    // goMz() {
    //   this.$router.push({
    //     name: "mzxq",
    //   });
    // },
    operation(operationId) {
      //对数据的操作-按钮
      this.operationPsotName = "id";
      this.issue = "确定删除该条科普？";
      this.hintTxt = "删除后不可恢复";
      this.operationPsotUrl = DelArticle; //修改即将调用的接口名字
      this.operationId = operationId; //修改组件id
      this.showHide = true; //显示组件
    },
    childByOperationBtn: function (showHide, dataCode) {
      //接收按钮式操作子组件传值
      this.showHide = showHide;
      if (dataCode == 1) {
        //接口调用成功，调用其他接口
        this.getList();
      }
    },
  },
};
</script>

<style lang="scss">
</style>
